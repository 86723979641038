import CustomLayout from "./wrapPageElement"

export const wrapPageElement = CustomLayout

export const onPreRouteUpdate= ({ location, prevLocation }) => {
     document.body.classList.remove('loaded'); 
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (window.location.hash?.startsWith('#toc-')) {
        window.location.replace(location.hash.replace('#toc-','#'));
    }
    document.body.classList.add('loaded');
}

export const disableCorePrefetching = () => true;