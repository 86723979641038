import * as React from 'react'
import { Link } from 'gatsby'
import * as breadcrumbStyles from './breadcrumb.module.scss'
import { useCurrentNavSegments, useCurrentBreadcrumbSegments } from '../../hooks/current-nav-segments';

const Breadcrumb = () => {
    const currentNavSegments = useCurrentNavSegments();

    const segments = useCurrentBreadcrumbSegments(currentNavSegments);

    return (
        <div className={"e2e-breadcrumb " + breadcrumbStyles.breadcrumb} 
             style={{visibility: segments.length <= 1 ? 'collapse' : null}}>
            {
                segments.map((n, ix) => 
                                        <span key={ix}>
                                            { ix > 0 && <>&nbsp;/&nbsp;</> }
                                            { ix > 0 ? <Link to={n.relativeUrl}>{n.title}</Link> : <>{n.title}</> }
                                        </span>)
            }
        </div>
    );
};

export default Breadcrumb;