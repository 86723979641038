export const onClientEntry = () => {
    const originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (...args) {
        const timestamp = process.env.DOCS_BUILDER_VERSION;
        args[1] = args[1]?.replace(/^.*\/page-data\/.*(app-data|page-data|sq\/d\/\d+)\.json$/gi, m => `${m}?v=${timestamp}`);

        if (process.env.GATSBY_DEV) {
            // Develop server returns error if there are any parameters here
            args[1] = args[1]?.replace(/^\/page-data\/app-data\.json\?v=\d+/gi, `/page-data/app-data.json`);
        }

        originalOpen.apply(this, args);
    }
}
