// extracted by mini-css-extract-plugin
export var active = "article-toc-module--active--7b72f";
export var articleOutline = "article-toc-module--article-outline--a6762";
export var contactWrap = "article-toc-module--contactWrap--d808d";
export var contributeLink = "article-toc-module--contributeLink--88135";
export var editIcon = "article-toc-module--editIcon--32560";
export var secondary = "article-toc-module--secondary--3b7e5";
export var tocHeader = "article-toc-module--tocHeader--65563";
export var tocLink = "article-toc-module--tocLink--46f3a";
export var tocWrap = "article-toc-module--tocWrap--228a9";
export var verticalIndicator = "article-toc-module--verticalIndicator--3c0cf";