import * as React from 'react';

/* eslint-disable react-hooks/exhaustive-deps */
export const useDelayedEffect = (func, inputs) => {
    const timeoutRef = React.useRef(null);

    React.useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(func, 0);

        return () => clearTimeout(timeoutRef.current);
    }, inputs);
}