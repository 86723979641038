import * as React from 'react'

const EditDocument = ({className}) => {
    return (<svg version="1.1" className={`docs-icon ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 21">
        <g>
            <path d="M11.9,16.8V8.6L12.5,8v9.4H0V5.5h10L9.4,6.1H0.6v10.6H11.9z M6.8,11.6l6.7-6.7l0.4,0.4l-7,7H5.6v-1.2l7-7l0.4,0.4l-6.7,6.7
                L6.8,11.6z M14.8,3.6C14.9,3.8,15,3.9,15,4c0,0.1-0.1,0.2-0.2,0.4l-0.5,0.5l-0.8-0.8l-0.4-0.4l0.5-0.5C13.8,3.1,13.9,3,14,3
                c0.1,0,0.2,0.1,0.4,0.2L14.8,3.6z">
            </path>
        </g>
    </svg>)
}

export default EditDocument;