import * as React from 'react';
import { useSiteMetadata } from '../../queries/site-medatata';
import { useIsTestEnvironment } from '../../hooks/is-test-environment';
import { useLocation } from '../../hooks/location';

const loadTagManager = (tagManagerContainerId) => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', tagManagerContainerId);
}

const loadGa = () => {
    const ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.src = 'https://stats.g.doubleclick.net/dc.js';
    document.body.appendChild(ga);
}

const isMobile = () => {
    var result = false;
    var matches = [
        /(Windows Phone(?: OS)?)\s(\d+)\.(\d+(\.\d+)?)/,
        /(Silk)\/(\d+)\.(\d+(\.\d+)?)/,
        /(Android|Android.*(?:Opera|Firefox).*?\/)\s*(\d+)\.?(\d+(\.\d+)?)?/,
        /(iPhone|iPod).*OS\s+(\d+)[._]([\d._]+)/,
        /(iPad).*OS\s+(\d+)[._]([\d_]+)/,
        /(MeeGo).+NokiaBrowser\/(\d+)\.([\d._]+)/,
        /(webOS)\/(\d+)\.(\d+(\.\d+)?)/,
        /(BlackBerry|BB10).*?Version\/(\d+)\.(\d+(\.\d+)?)/,
        /(PlayBook).*?Tablet\s*OS\s*(\d+)\.(\d+(\.\d+)?)/,
        /(MSIE)\s+(\d+)\.(\d+(\.\d+)?)/,
        /(tizen).*?Version\/(\d+)\.(\d+(\.\d+)?)/i,
        /(sailfish).*rv:(\d+)\.(\d+(\.\d+)?).*firefox/i,
        /(Mobile).*rv:(\d+)\.(\d+(\.\d+)?).*Firefox/
    ];
    for (var i = 0; i < matches.length; i++) {
        result = matches[i].test(window.navigator.userAgent);
        if (result) {
            break;
        }
    }
    return result;
}

const loadTrackers = (tagManagerContainerId) => {
    if (isMobile()) {
        window._gaq = window._gaq || [];
        window._gaq.push(['_setAccount', 'UA-111455-1'],
            ['_setDomainName', '.telerik.com'],
            ['_addIgnoredRef', 'telerik.com'],
            ['_trackPageview']);
        loadGa();
    } else {
        loadTagManager(tagManagerContainerId);
    }
}

const oneTrustFallback = () => {
    document.querySelectorAll('script.optanon-category-1')
        .forEach(n => {
            const scriptNode = document.createElement('script');
            scriptNode.innerText = n.innerText;
            n.parentNode.insertBefore(scriptNode, n);
            n.remove();
        });
};

const oneTrustError = () => {
    if (document.readyState !== 'loading') {
        oneTrustFallback();
        return;
    }

    document.addEventListener('readystatechange', oneTrustFallback, { once: true });
}

const GoogleTagManager = () => {
    const siteMetadata = useSiteMetadata();
    const isTestEnvironment = useIsTestEnvironment();
    const location = useLocation();

    React.useEffect(() => {
        const oneTrustScriptTemplate = isTestEnvironment ? `${siteMetadata.oneTrustScriptTemplate}-test` : siteMetadata.oneTrustScriptTemplate;
        const oneTrustScriptSrc = `https://cdn.cookielaw.org/consent/${oneTrustScriptTemplate}/otSDKStub.js`;

        window.loadTrackers = loadTrackers;

        const tagManager = document.createElement('script');
        tagManager.type = 'text/plain';
        tagManager.className = isMobile() ? 'optanon-category-2' : 'optanon-category-1';
        tagManager.innerText = `window.loadTrackers(${JSON.stringify(siteMetadata.tagManagerContainerId)})`;
        tagManager.async = true;
        document.body.appendChild(tagManager);

        const oneTrustScript = document.createElement('script');
        oneTrustScript.type = 'text/javascript';
        oneTrustScript.src = oneTrustScriptSrc;
        oneTrustScript.onerror = oneTrustError;
        oneTrustScript.setAttribute('data-domain-script', oneTrustScriptTemplate);
        oneTrustScript.setAttribute('data-language', 'en');
        oneTrustScript.async = true;
        document.body.appendChild(oneTrustScript);

    }, [isTestEnvironment, siteMetadata]);

    React.useEffect(() => {
        if (isMobile()) {
            window._gaq = window._gaq || [];
            window._gaq.push(['_trackPageview']);
        }
    }, [location.pathname]);
}

export default GoogleTagManager;