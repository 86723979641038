const MAX_UPTIME = 30 * 60 * 1000;

export const onClientEntry = () => {
    window.__appInitDate = new Date().getTime();
}

export const onPreRouteUpdate = ({location}) => {
    const duration = new Date().getTime() - window.__appInitDate;
    if (duration > MAX_UPTIME) {
        window.location.href = location.href;
        return false;
    }
}