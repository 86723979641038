import * as React from 'react';
import * as searchBarStyles from './search-bar.module.scss';
import { navigate } from 'gatsby';
import { uri } from '../../utilities/uri';
import { useLocation } from '../../hooks/location';
import { useSiteMetadata } from '../../queries/site-medatata';
import SearchBarIcon from '../icons/search-bar-icon';
import ClearIcon from '../icons/clear-icon';
import ThemeSwitcher from '../theme-switcher/theme-switcher';
import MobileMenuSwitch from '../mobile-menu-switch/mobile-menu-switch';
import { usePageData } from '../../hooks/page-data';

const SearchBar = ({ topPx }) => {
    const location = useLocation();
    const siteMetadata = useSiteMetadata();
    const storageKey = `searchItemsStorageKey${siteMetadata.searchEngineId}`;

    const [searchText, setSearchText] = React.useState('');
    const [isInputFocused, setIsInputFocused] = React.useState(false);
    const [showClearBtn, setShowClearBtn] = React.useState(false);
    const searchInputRef = React.useRef(null);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchTextParam = params.get('q');

        let searchText = '';
        if (searchTextParam) {
            searchText = searchTextParam;
        }

        setSearchText(searchText);
    }, [location, storageKey]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchText === '') {
            return;
        }

        navigate(uri`/search?q=${searchText}`);
    }

    //// Set and remove document scroll-padding-top style, because when typing in input the page scrolling to top (bug with Chrome).
    const onFocus = () => {
        document.documentElement.classList.add(`searchBarFocused`);
        setIsInputFocused(true);
    }

    const onBlur = () => {
        document.documentElement.classList.remove(`searchBarFocused`);
        setIsInputFocused(false);
    }

    React.useEffect(() => {
        return () => onBlur();
    }, []);

    React.useEffect(() => {
        let timeRef = setTimeout(() => {
            timeRef = null;
            if (isInputFocused) {
                setShowClearBtn(true);
            } else {
                setShowClearBtn(false);
            }
        }, 150);

        return () => {
            if (timeRef) {
                clearTimeout();
            }
        }
    }, [isInputFocused]);

    const data = usePageData();
    const fullscreen = React.useMemo(() => data?.markdownRemark?.frontmatter?.fullscreen || false, [data]);

    const clearInputField = (e) => {
        onFocus();
        e.stopPropagation();
        setSearchText('');
        searchInputRef.current?.focus();
    };

    return (
        <div className={`${searchBarStyles.container} ${fullscreen ? searchBarStyles.fullscreen : ''}`} style={{ top: `${topPx}px` }} >
            <div className={searchBarStyles.mobileSwitchContainer}>
                <MobileMenuSwitch />
            </div>
            <div className={searchBarStyles.searchContainer} >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={searchBarStyles.formContainer}>
                        <div className={searchBarStyles.iconContainer}>
                            <SearchBarIcon className={`${searchBarStyles.searchIcon} ${searchText !== '' ? searchBarStyles.searchIconColor : ''}`}/>
                        </div>
                        <input ref={searchInputRef} className={searchBarStyles.searchBar} value={searchText}
                            onChange={(e) => setSearchText(e.target.value)} autoComplete="off" type="text" placeholder="Search..." name="search"
                            onBlur={onBlur} onFocus={onFocus} />
                        {
                            searchText !== '' &&
                            <button type="button" className={`${searchBarStyles.cancelButton} ${showClearBtn ? '' : searchBarStyles.cancelBtnHide}`} onClick={clearInputField} aria-label='Clear'>
                                <ClearIcon />
                            </button>
                        }
                    </div>
                </form>
            </div>
            <div className={searchBarStyles.themeSwitchContainer}>
                <ThemeSwitcher />
            </div>
        </div>
    )
}

export default SearchBar;