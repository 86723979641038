import React from "react";
import { useSplunkLogger } from "../../hooks/splunk-logger";

const ErrorHandler = () => {
    const logger = useSplunkLogger();

    React.useEffect(() => {
        const controller = new AbortController();
        window.addEventListener('error', logger, { signal: controller.signal });
        window.addEventListener('beforeunload', () => { window.__isUnloading = true }, { signal: controller.signal });

        return () => {
            controller.abort();
        }
    }, [logger]);
}

export default ErrorHandler;