import { withPrefix } from "gatsby";
import { useSideNavigation } from "../queries/side-navigation";
import * as React from "react";

export const CurrentNavSegmentsContext = React.createContext([]);

const getNavMap = (navRoot) => {
    const queue = navRoot.childrenSegments.map(segment => ({segment, ancestors: []}));

    const result = {};
    while (queue.length > 0) {
        const {segment, ancestors} = queue.pop();

        if (segment.relativeUrl) {
            const prefixedUrl = withPrefix(segment.relativeUrl);
            result[prefixedUrl] = [segment, ...ancestors];
        }

        const nextSegments = segment.childrenSegments.map(s => ({segment: s, ancestors: [segment, ...ancestors]}));

        queue.push(...nextSegments);
    }

    return result;
}

export const useNavMap = () => {
    const navRoot = useSideNavigation();
    const result = React.useMemo(() => getNavMap(navRoot), [navRoot]);
    return result;
}

export const useCurrentNavSegments = () => {
    return React.useContext(CurrentNavSegmentsContext);
}

export const useCurrentBreadcrumbSegments = (currentNavSegments) => {
    const result = React.useMemo(() => 
        currentNavSegments
            .filter(s => !s.hideChildren || s.childrenSegments?.length > 1)
            .map(s => ({ title: s.title, relativeUrl: getFirstNavigableNode(s)?.relativeUrl }))
            .reverse(), 
        [currentNavSegments]);

    return result;
}

const getFirstNavigableNode = (nav) => {
    let result = nav;
    while (result && !result.relativeUrl) {
        result = result.childrenSegments[0] || null;
    }

    return result;
}