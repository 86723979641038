// extracted by mini-css-extract-plugin
export var cancelBtnHide = "search-bar-module--cancelBtnHide--02cf5";
export var cancelButton = "search-bar-module--cancelButton--8d8e5";
export var container = "search-bar-module--container--36969";
export var formContainer = "search-bar-module--formContainer--5b18d";
export var fullscreen = "search-bar-module--fullscreen--3f634";
export var iconContainer = "search-bar-module--iconContainer--b405b";
export var mobileSwitchContainer = "search-bar-module--mobileSwitchContainer--e909d";
export var searchBar = "search-bar-module--searchBar--bac79";
export var searchContainer = "search-bar-module--searchContainer--615df";
export var searchIcon = "search-bar-module--searchIcon--6aa21";
export var searchIconColor = "search-bar-module--searchIconColor--06cee";
export var themeSwitchContainer = "search-bar-module--themeSwitchContainer--e41c7";