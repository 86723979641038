import * as React from 'react'
import { useTopNavigation } from '../../queries/top-navigation';
import SideNav from '../side-nav/side-nav';

import '../../styles/global.scss'
import * as layoutStyles from './layout.module.scss'
import { LocationContext } from '../../hooks/location';
import { PageDataContext } from '../../hooks/page-data';
import { AnnouncementBannerHeightContext } from '../../hooks/announcement-banner-height';
import { PreferredLanguageContext } from '../../hooks/preferred-language-context';

import AnnouncementBanner from '../announcement-banner/announcement-banner';
import ErrorHandler from '../error-handler/error-handler';
import GoogleTagManager from '../google-tag-manager/google-tag-manager';
import SearchBar from '../search-bar/search-bar';
import { CurrentNavSegmentsContext, useNavMap } from '../../hooks/current-nav-segments';
import ArticleToc from '../article-toc/article-toc';
import BreadCrumb from '../breadcrumb/breadcrumb'

let fragment = null;

const navHeight = 60;
const searchBarHeight = 72;
const preferredLanguageKey = "Selected_TabStrip_Language_Key"

const Layout = ({ children, location, data }) => {
    const headref = React.useRef(null);
    const topNavigation = useTopNavigation();

    const [bannerHeight, setBannerHeight] = React.useState(0);
    const [preferredLanguageName, setPreferredLanguageName] = React.useState(null);

    const navMap = useNavMap();
    const currentNavSegments = React.useMemo(() => navMap[location.pathname] || [], [navMap, location])

    React.useEffect(() => {
        const lang = localStorage?.getItem(preferredLanguageKey);
        setPreferredLanguageName(lang);
    }, []);

    const languageContext = React.useMemo(() => {
        return {
            preferredLanguageName: preferredLanguageName,
            setPreferredLanguageName: (value) => {
                localStorage?.setItem(preferredLanguageKey, value);
                setPreferredLanguageName(value);
            }
        }
    }, [preferredLanguageName]);

    const bannerHeightRef = React.useRef(0);
    const onBannerHeightChanged = React.useCallback((heightPx) => {
        setBannerHeight(heightPx);
        document.querySelector(`nav.TK-Nav`).style.top = `${heightPx}px`;
        document.documentElement.style.scrollPaddingTop = `${146 + heightPx}px`;

        const diff = heightPx - bannerHeightRef.current;
        bannerHeightRef.current = heightPx;
        if (diff !== 0) {
            setTimeout(() => window.scrollTo(window.scrollX, window.scrollY - diff), 100)
        }
    }, []);

    React.useEffect(() => {
        if (!process.env.GATSBY_DEV) {
            return;
        }

        //// In dev mode there is no server rendering and the innerHtml set does not execute scripts
        //// furthermore the 404 page seems to reinit the navigation (due to an unfixed bug) so we need to reinit the nav (despite it already being loaded)
        window.TLRK_NAV_INITIALIZED = false;
        fragment = document.createRange().createContextualFragment(headref.current.innerHTML.replace('.mjs', '.mjs?' + new Date().getTime()));
        headref.current.innerHTML = '';
        headref.current.appendChild(fragment);
    }, []);

    const hideToc = React.useMemo(() => data?.markdownRemark?.fields?.hideToc || false, [data]);
    const fullscreen = React.useMemo(() => data?.markdownRemark?.frontmatter?.fullscreen || false, [data]);

    return (
        <CurrentNavSegmentsContext.Provider value={currentNavSegments}>
            <PreferredLanguageContext.Provider value={languageContext}>
                <PageDataContext.Provider value={data}>
                    <LocationContext.Provider value={location}>
                        <ErrorHandler />
                        <AnnouncementBannerHeightContext.Provider value={bannerHeight}>
                            <GoogleTagManager />
                            <AnnouncementBanner bannerHeightChanged={onBannerHeightChanged} />
                            <span className={bannerHeight > 0 ? `bannerActive` : ''} ref={headref} dangerouslySetInnerHTML={{ __html: topNavigation.headerMarkup }}></span>
                            <SearchBar topPx={navHeight + bannerHeight} />
                            <div className={`d-flex app-body ${fullscreen ? layoutStyles.fullscreen : ''}`} style={{ minHeight: `calc(100vh - ${navHeight + searchBarHeight + bannerHeight}px)` }}>
                                <div className={layoutStyles.nav + ' nav-container'}>
                                    <SideNav location={location} topPx={navHeight + searchBarHeight + bannerHeight} />
                                </div>
                                <div className={`main-container u-mw0 flex-grow-1 ${hideToc ? layoutStyles.tocHidden : ''}`}>
                                    <div className='d-flex flex-column h-100'>
                                        <div className={`e2e-content flex-grow-1 d-flex ${layoutStyles.main}`}>
                                            <div className={`${layoutStyles.body}`}>
                                                {!fullscreen && <BreadCrumb></BreadCrumb>}
                                                {children}
                                            </div>
                                            <div className={`d-none d-xl-block ${layoutStyles.toc}`}>
                                                <div className={"e2e-right-nav " + layoutStyles.rightNav} style={{ top: `${164 + bannerHeight}px` }}>
                                                    {
                                                        !hideToc && !fullscreen && <ArticleToc></ArticleToc>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: topNavigation.footerMarkup }}></div>
                                    </div>
                                </div>
                            </div>
                        </AnnouncementBannerHeightContext.Provider>
                    </LocationContext.Provider>
                </PageDataContext.Provider>
            </PreferredLanguageContext.Provider>
        </CurrentNavSegmentsContext.Provider>);
}

export default Layout;