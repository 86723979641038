export const uri = (arr, ...arg) => {
    const result = [];

    for (let i = 0; i < arr.length; i++) {
        result.push(arr[i]);

        if (arg.length > i) {
            result.push(encodeURIComponent(arg[i]));
        }
    }

    return result.join('');
}