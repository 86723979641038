
import React from 'react';
import { useIsTestEnvironment } from './is-test-environment';
import { useCommonQuery } from '../queries/common-query';

export const useSplunkCredentials = () => {
    const isTestEnvironment = useIsTestEnvironment();

    const { site: {siteMetadata: { splunkCredentials: { testEnv, liveEnv } }} } = useCommonQuery();

    const splunkCredentials = React.useMemo(() => isTestEnvironment ? testEnv : liveEnv, [isTestEnvironment, testEnv, liveEnv]);

    return splunkCredentials;
}
