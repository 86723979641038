import * as React from 'react'
import * as tocStyles from './article-toc.module.scss'
import ContactSupport from '../contact-support/contact-support';
import { useSiteMetadata } from '../../queries/site-medatata';
import { useAnnouncementBannerHeight } from '../../hooks/announcement-banner-height';
import EditDocument from '../icons/edit-document';
import { usePageData } from '../../hooks/page-data';
import { sortByDate } from '../../utilities/sort-by-date';

const scrollParentTo = (id) => {
    if (!id) {
        return;
    }

    const element = document.getElementById(id);

    if (!element || !element.offsetParent || element.offsetParent.scrollHeight <= element.offsetParent.offsetHeight) {
        return;
    }

    const pos = element.offsetTop - element.offsetParent.offsetHeight / 2;

    element.offsetParent.scrollTo({ top: pos });
}

const getScrollPadding = () => {
    const paddingPx = window.getComputedStyle(document.documentElement)['scrollPaddingTop'];

    if (!paddingPx) {
        return 0;
    }

    const result = parseInt(paddingPx.replace('px', ''), 10)
    return result;
}

const ArticleToc = () => {
    const announcementHeight = useAnnouncementBannerHeight();
    const data = usePageData();

    const relativeUrl = data?.markdownRemark?.fields?.relativeUrl;

    const headings = React.useMemo(() => {
        const result = [];

        if (data?.markdownRemark) {
            if (!data?.markdownRemark?.htmlAst?.children) {
                return result;
            }

            for (const node of data.markdownRemark.htmlAst.children) {
                const match = node.tagName?.match(/^h(?<level>\d$)/i)
                if (!match || !node.properties[`dataToc`]) {
                    continue;
                }

                result.push({
                    id: node.properties.id,
                    text: node.properties[`dataToc`],
                    level: parseInt(match.groups.level, 10)
                });
            }
        }

        if (data?.release && data?.latestDevRelease) {
            if (!data?.release?.nodes && data?.latestDevRelease.nodes) {
                return result;
            }

            for (const node of [...data?.latestDevRelease.nodes, ...data.release.nodes].sort(sortByDate)) {
                result.push({
                    id: node.name,
                    text: node.name,
                    level: parseInt(2, 10)
                });
            }
        }

        return result;
    }, [data]);

    const tocItems = React.useMemo(() => {
        const result = headings
            .filter(h => h.level === 2 || h.level === 3);

        return result;
    }, [headings]);

    const [activeId, setActiveId] = React.useState(null);

    const timerRef = React.useRef(null);

    const articleRef = React.useRef(null);

    React.useEffect(() => {
        const handler = () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            let newId = null;
            let scrollY = Math.ceil(window.scrollY + getScrollPadding() + announcementHeight);

            for (let item of tocItems) {
                const el = document.getElementById(item.id);

                if (!el) {
                    continue;
                }

                if (el.offsetTop > scrollY + 1) {
                    break;
                }

                newId = el?.id;
            }

            setActiveId(newId);

            timerRef.current = setTimeout(() => {
                if (newId) {
                    scrollParentTo(`toc-${newId}`);
                } else {
                    articleRef?.current?.scrollTo({ top: 0 });
                }
            }, 100);
        };

        window.addEventListener(`scroll`, handler, { passive: true });
        handler();

        return () => window.removeEventListener(`scroll`, handler);
    }, [tocItems, announcementHeight]);

    const siteMetadata = useSiteMetadata();

    return (<>
        {tocItems.length > 0 && <div className={tocStyles.articleOutline}>
            <div className={tocStyles.tocHeader}>In this article</div>
            <div ref={articleRef} className={"dark-scrollbar " + tocStyles.tocWrap} style={{ maxHeight: `calc(100vh - ${398 + announcementHeight}px)` }}>
                <div className={tocStyles.verticalIndicator}>
                    {tocItems.map(i => (<a key={i.id} id={`toc-${i.id}`} className={`${tocStyles.tocLink} ${i.id === activeId ? tocStyles.active : ''} ${i.level === 3 ? tocStyles.secondary : ''}`} href={`#${i.id}`}>{i.text}</a>))}
                </div>
            </div>
        </div>}
        <div className={tocStyles.contactWrap}>
            <ContactSupport />
            {siteMetadata.contributeUrl && <a className={tocStyles.contributeLink} href={`${siteMetadata.contributeUrl}${relativeUrl}.md`} target='_blank' rel="noreferrer">
                <EditDocument className={tocStyles.editIcon} />
                Improve this article
            </a>}
        </div>
    </>)
}

export default ArticleToc;