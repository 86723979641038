import * as React from 'react';
import { useSideNavigation } from '../../queries/side-navigation';
import SideNavSection from '../side-nav-section/side-nav-section';

import * as sideNavStyles from './side-nav.module.scss'

const SideNav = ({topPx}) => {
    const navRoot = useSideNavigation();

    return (<div className={"e2e-left-nav " + sideNavStyles.container + " dark-scrollbar"} style={{top: `${topPx}px`, maxHeight: `calc(100vh - ${topPx}px)`}}>
            { navRoot.childrenSegments.map(n => <SideNavSection key={n.title} navNode={n}></SideNavSection>) }
            </div>)
}

export default SideNav;