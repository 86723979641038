import * as React from 'react';
import { useLocation } from '../hooks/location';
import { useSiteMetadata } from '../queries/site-medatata';

export const useIsTestEnvironment = () => {
    const location = useLocation();
    const siteMetadata = useSiteMetadata();

    const isTest = React.useMemo(() => {
        const result = !location.href?.toLowerCase().startsWith(siteMetadata.rootUrl.toLowerCase());
        
        return result
    },[siteMetadata, location])

    return isTest;
}