import * as React from 'react'
import * as styles from './mobile-menu-switch.module.scss'
import { useLocation } from '../../hooks/location';

const MobileMenuSwitch = () => {
    const [mobileNavVisible, setMobileNavVisible] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.toggle('mobile-nav', mobileNavVisible);

        return () => document.body.classList.remove('mobile-nav');
    }, [mobileNavVisible])

    const location = useLocation();

    React.useEffect(() => setMobileNavVisible(false), [location.pathname])

    return (<button onClick={() => setMobileNavVisible(!mobileNavVisible)} className={`d-md-none ${styles.btn}`} aria-label='Menu'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
        <title>menu</title>
        <path d="M0,17H4V13H0Zm6-7H22l2-2H6ZM0,11H4V7H0ZM24,2H6V4H22ZM0,23H4V19H0Zm6-7H22l2-2H6ZM0,5H4V1H0ZM6,22H22l2-2H6Z"></path>
    </svg></button>)
}

export default MobileMenuSwitch;