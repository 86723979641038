import { graphql, useStaticQuery } from 'gatsby';

export const useCommonQuery = () => {
  const data = useStaticQuery(graphql`fragment navFragment on NavSegment {
    title
    relativeUrl
    hideChildren
    hidden
    tag
  }
  
  query CommonQuery{
    site {
      siteMetadata {
        productId
        productName
        productUrl
        productTrialUrl
        rootUrl
        searchEngineId
        googleApiKey
        defaultTitlePrefix
        googleSearchApiBaseUrl
        ctaOverviewMessage
        ctaIntroMessage
        tagManagerContainerId
        oneTrustScriptTemplate
        feedbackFormCss
        feedbackFormScript
        productCode
        splunkCredentials {
            testEnv {
              index,
              token,
              url
            },
            liveEnv {
              index,
              token,
              url
            }
        }
      }
    }
    avatarIcon: file(sourceInstanceName: {eq: "asset-avatar-icon"}) {
      publicURL
    }
    noResultsIcon: file(sourceInstanceName: {eq: "asset-no-results-icon"}) {
      publicURL
    }
    telerikNavigation {
      footerMarkup
      headerMarkup
    }
    demoConfiguration {
      metaFileName
      defaultTheme
      actions
      themes
      uris
    }
    navSegment(parentSegment: {id: {eq: null}}) {
      ...navFragment
      childrenSegments {
        ...navFragment
        childrenSegments {
          ...navFragment
          childrenSegments {
            ...navFragment
            childrenSegments {
              ...navFragment
              childrenSegments {
                ...navFragment
                childrenSegments {
                  ...navFragment
                  childrenSegments {
                    ...navFragment
                    childrenSegments {
                      ...navFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`);

  return data;
};
