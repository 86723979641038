import { createContext, useContext } from "react";

export const LocationContext = createContext(null);

export const useLocation = () => {
    const result = useContext(LocationContext);

    if (!result) {
        throw new Error('Location context not initialized');
    }

    return result;
}