import * as React from 'react';
import { withPrefix } from 'gatsby';
import * as bannerStyle from './announcement-banner.module.scss';
import { uri } from '../../utilities/uri';

const bannerDismissedKey = `dismissed-banner-${withPrefix('/')}`;

const AnnouncementBanner = ({ bannerHeightChanged }) => {
    const [banner, setBanner] = React.useState(null);

    const bannerRef = React.useRef(null);

    const getBanner = React.useCallback(async () => {
        const docsUrl = new URL(withPrefix(`/`), window.location);
        const apiUrl = uri`/webapi/announcements/getmarkup?url=${docsUrl}&responseType=json`;

        const response = await fetch(apiUrl, {
            method: "GET",
        });

        if (response.status === 404) {
            setBanner(null);
            return;
        }

        if (!response.ok) {
            setBanner(null);
            console.warn(`Announcement request failed: \n`, response);
            return;
        }

        const actualData = await response.json();

        if (localStorage.getItem(bannerDismissedKey) === actualData?.content) {
            setBanner(null);
            return;
        }

        setBanner(actualData.content);
    }, []);

    React.useEffect(() => {
        const observer = new ResizeObserver(() => {
            bannerHeightChanged(bannerRef.current?.offsetHeight || 0);
        });
        observer.observe(bannerRef.current);

        return () => observer.disconnect();
    }, [bannerHeightChanged]);

    React.useEffect(() => {
        getBanner();
    }, [getBanner]);

    const hideBanner = React.useCallback(() => {
        setBanner(null);
        localStorage.setItem(bannerDismissedKey, banner);
    }, [banner]);

    return (
        <>
            <div ref={bannerRef} style={{display: banner? null : 'none'}} className={`announcement-banner ${bannerStyle.annBanner}`}>
                <div dangerouslySetInnerHTML={{ __html: banner || '' }}></div>
                <button onClick={hideBanner} className={bannerStyle.btnClose}>×</button>
            </div>
        </>
    )
}

export default AnnouncementBanner;