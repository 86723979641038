import * as React from 'react';
import * as themeSwitchStyle from './theme-switcher.module.scss';

const setDocumentAttribute = (isDark) => {
    document.documentElement.setAttribute('data-is-dark', isDark);
}

const setDocumentClass = (isDark) => {
    document.body.classList.toggle('t-dark', isDark);
    document.body.classList.toggle('t-light', !isDark);
}

const ThemeSwitcher = () => {
    const [isDark, setIsDark] = React.useState(false);

    React.useEffect(() => {
        let result = null;

        const savedValue = localStorage.getItem('isDark');
        if (savedValue) {
            try {
                result = !!JSON.parse(savedValue);
            } catch (e) {
                setDocumentClass(false);
                console.error(e);
            }
        }

        if (result) {
            localStorage.setItem('isDark', JSON.stringify(result));
            setDocumentClass(result);
        }

        result = !!result;

        setIsDark(result);
    }, []);

    React.useEffect(() => {
        setDocumentAttribute(isDark);
    }, [isDark]);

    const setThemeOnClick = () => {
        setIsDark(!isDark);
        localStorage.setItem('isDark', JSON.stringify(!isDark));
        setDocumentClass(!isDark);
    }

    return (
        <button className={`btn-switch-theme ${themeSwitchStyle.switchBtn}`} onClick={setThemeOnClick} aria-label='Switch Theme'>
            <span className={themeSwitchStyle.switchIcon}></span>
        </button>
    )
}

export default ThemeSwitcher;