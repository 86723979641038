import * as React from 'react';
import * as styles from './contact-support.module.scss';
import { useSiteMetadata } from '../../queries/site-medatata';

// Domain is ours so this bug can't happen even on older browsers, furthermore we need the referrer for reports
/* eslint-disable react/jsx-no-target-blank */
const ContactSupport = () => {
    const siteMetadata = useSiteMetadata();

    return (<>
        <div className={styles.supportTitle}>Not finding the help you need?</div>
        <a href={`https://www.telerik.com/account/support-center/contact-us/technical-support?pid=${encodeURIComponent(siteMetadata.productId)}`} className={styles.contactSupport} target='_blank'>Contact Support</a>
    </>)
}

export default ContactSupport;