import * as React from 'react'
import * as sideNavStyles from './side-nav-section.module.scss'
import { Link } from 'gatsby';
import Chevron from '../icons/chevron';
import { useDelayedEffect } from '../../hooks/delayed-effect';
import { useAnnouncementBannerHeight } from '../../hooks/announcement-banner-height';
import { useCurrentNavSegments } from '../../hooks/current-nav-segments';

const isOutsideView = (element) => {
    const midPoint = Math.ceil(element.offsetTop + (element.offsetHeight / 2));
    const result = midPoint < element.offsetParent.scrollTop || midPoint > element.offsetParent.scrollTop + element.offsetParent.offsetHeight;
    return result
}

const scrollParentToElement = (element) => {
    const midPoint = Math.ceil(element.offsetTop + (element.offsetHeight / 2));
    const parent = element.offsetParent;

    const newScrollY = Math.max(0, Math.ceil(midPoint - (parent.offsetHeight / 2)));

    parent.scrollTo({top: newScrollY});
}

const SideNavSection = ({ navNode, level }) => {
    const sectionRef = React.useRef(null)

    const [expanded, setExpanded] = React.useState(false);

    const [isActive, setIsActive] = React.useState(false);

    const currentNavSegments = useCurrentNavSegments();

    React.useEffect(() => {
        const nodeIndex = currentNavSegments.indexOf(navNode);

        setIsActive(!navNode.hidden && (nodeIndex === 0 || (navNode.hideChildren && nodeIndex > 0)));
        setExpanded(!navNode.hidden && nodeIndex > 0);
    }, [currentNavSegments, navNode]);


    const bannerHeight = useAnnouncementBannerHeight();

    useDelayedEffect(() => {
        if (!sectionRef.current) {
            return;
        }
    
        if (sectionRef.current.offsetParent && isActive && isOutsideView(sectionRef.current)) {
            scrollParentToElement(sectionRef.current);
        }
    }, [isActive, bannerHeight]);

    const lvl = level || 0;
    let linkStyle = { paddingLeft: `${16 * (lvl + 1)}px` }

    const sectionClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    }

    const activeClass = isActive ? sideNavStyles.active : '';

    return (<>
        { !navNode.hidden && <div ref={sectionRef} className={`${sideNavStyles.section} ${expanded ? sideNavStyles.expanded : ''}`}>
            {!navNode.hideChildren && navNode.childrenSegments && navNode.childrenSegments.length > 0 && <>
                <button style={linkStyle} className={`${sideNavStyles.group}`} onClick={sectionClick}>
                    <div className={sideNavStyles.groupText} data-tag={navNode.tag}>
                        {lvl > 0 && <Chevron className={sideNavStyles.expander} /> }
                        {navNode.title}
                    </div>
                </button>
            </>}
            {(navNode.hideChildren || !navNode.childrenSegments || navNode.childrenSegments.length <= 0) 
                && <Link style={linkStyle} className={`${sideNavStyles.page} ${activeClass}`} data-tag={navNode.tag} to={navNode.relativeUrl || navNode.childrenSegments[0]?.relativeUrl || ``}>{navNode.title}</Link>}
            {!navNode.hideChildren && expanded && navNode.childrenSegments.map(s => <SideNavSection key={s.title} navNode={s} level={lvl + 1}></SideNavSection>)}
        </div> }
    </>)
}

export default SideNavSection;