import * as React from 'react'

const SearchBarIcon = ({ className }) => {
    return (<svg className={`docs-icon ${className}`} width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8438 15.1562C16.0312 15.3438 16.0312 15.6875 15.8438 15.875C15.75 15.9688 15.625 16 15.5 16C15.3438 16 15.2188 15.9688 15.0938 15.875L10.6875 11.4375C9.53125 12.4375 8.0625 13 6.46875 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.875 0 6.46875 0C10.0312 0 12.9688 2.9375 12.9688 6.5C12.9688 8.125 12.4062 9.59375 11.4062 10.75L15.8438 15.1562ZM6.5 12C9.53125 12 12 9.5625 12 6.5C12 3.46875 9.53125 1 6.5 1C3.4375 1 1 3.46875 1 6.5C1 9.53125 3.4375 12 6.5 12Z" />
    </svg>
    )

}

export default SearchBarIcon;