import React from 'react';
import { useSplunkCredentials } from './splunk-credentials';

export const useSplunkLogger = () => {
    const splunkCredentials = useSplunkCredentials();

    const logSplunkError = React.useCallback(async (errorEvent) => {
        if (window.__isUnloading) {
            console.warn(`Skipping error logging after unload event!`);
            return;
        }

        const headers = {
            'Authorization': `Splunk ${splunkCredentials.token}`,
            'Content-Type': 'application/json'
        };

        const data = {
            sourcetype: '_json',
            index: splunkCredentials.index,
            host: window.location.hostname,
            event: {
                type: 'error',
                error: errorEvent.error.message.toString(),
                stackTrace: errorEvent.error.stack || '',
                screen: `${window.screen.width}x${window.screen.height}`,
                userAgent: window.navigator.userAgent,
                url: window.location.href,
            }
        };

        try {
            await fetch(splunkCredentials.url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            });
        } catch (ex) {
            console.error('Splunk logger failed with: ', ex);
        }

    }, [splunkCredentials]);

    return logSplunkError;
}